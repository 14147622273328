import $ from "jquery";
import "jquery.nicescroll";

export function scroll(obj) {
  $(obj).niceScroll({
    cursorcolor: "#000000",           // 改变滚动条颜色，使用16进制颜色值
    cursoropacitymin: 0,              // 当滚动条是隐藏状态时改变透明度, 值范围 1 到 0
    cursoropacitymax: 1,              // 当滚动条是显示状态时改变透明度, 值范围 1 到 0
    cursorwidth: "7px",               // 滚动条的宽度，单位：便素
    cursorborderradius: "5px",        // 滚动条圆角（像素）
    scrollspeed: 60,                  // 滚动速度
    mousescrollstep: 40,              // 鼠标滚轮的滚动速度 (像素)
    touchbehavior: false,             // 激活拖拽滚动
    hwacceleration: true,             // 激活硬件加速
    boxzoom: false,                   // 激活放大box的内容
    dblclickzoom: true,               // (仅当 boxzoom=true时有效)双击box时放大
    gesturezoom: true,                // (仅 boxzoom=true 和触屏设备时有效) 激活变焦当out/in（两个手指外张或收缩）
    grabcursorenabled: true,          // (仅当 touchbehavior=true) 显示“抓住”图标display "grab" icon
    // autohidemode: true,              // 隐藏滚动条的方式, 可用的值:
  });
}
