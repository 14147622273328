import request from "@utils/request";

/**
 * 获取导航列表（根据导航分类nav_category_id获取导航列表）
 * @param {*} queryWhere =>{ nav_category_id: 2 }
 * @returns
 */
export function getNavList(queryWhere) {
    return request.get("nav/list", queryWhere, { login: false });
}

/**
 * 获取轮播图列表（根据轮播图分组id获取轮播图列表）
 * @param {*} queryWhere =>{ cid: 1 }
 * @returns
 */
export function getCarouselList(queryWhere) {
    return request.get("carousel/list", queryWhere, { login: false });
}

/**
 * 获取文章列表（根据文章分类id获取文章列表）
 * @param {*} queryWhere => {article_category_id:1}
 * @returns
 */
export function getArticleList(queryWhere) {
    return request.get("article/list", queryWhere, { login: false });
}

/**
 * 根据文章id获取文章详情
 * @param {*} queryWhere => {id:1}
 * @returns
 */
export function getArticle(queryWhere) {
    return request.get("article/detail", queryWhere, { login: false });
}

/**
 * 根据文章分类id获取文章分享详情（含包含的children）
 * @param {*} queryWhere => {id : 11}
 * @returns
 */
export function getArticleCategory(queryWhere) {
    return request.get("articleCategory/detail", queryWhere, { login: false });
}

/**
 * 根据id父级详情
 * @param {*} queryWhere
 * @returns
 */
export function getArticleCategoryParentById(queryWhere) {
    return request.get("articleCategory/parent", queryWhere, { login: false });
}

/**
 * 获取辅助教学目录（图书） 列表
 * @param {*} queryWhere => { pid: 7 }
 * @returns
 */
export function getGoodsCategories(queryWhere) {
    return request.get("goodsCategory/list", queryWhere, { login: false });
}

/**
 * 根据商品分类id获取商品分类详情
 * @param {*} queryWhere  => { id: 7 }
 * @returns
 */
export function getGoodsCategory(queryWhere) {
    return request.get("goodsCategory/detail", queryWhere, { login: false });
}

/**
 * 获取图书列表（根据图书分类id获取图书列表）
 * @param {*} queryWhere => {goods_category_id:10}
 * @returns
 */
export function getGoodsList(queryWhere) {
    return request.get("goods/list", queryWhere, { login: false });
}

/* 根据图书id获取图书详情 */
export function getGoods(queryWhere) {
    return request.get("goods/detail", queryWhere, { login: false });
}

/**
 * 获取课题研究列表
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getKeyProjects(queryWhere) {
    return request.get("keyProject/list", queryWhere, { login: false });
}

/**
 * 根据课题研究id获取课题研究详情
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getKeyProject(queryWhere) {
    return request.get("keyProject/detail", queryWhere, { login: false });
}

/* 人才库数据列表 */
export function getTrainPersons(queryWhere) {
    return request.get("trainPerson/list", queryWhere, { login: false });
}

/**
 * 获取发展历程列表
 * @param {*} queryWhere
 * @returns
 */
export function getvideoReviews(queryWhere) {
    return request.get("videoReview/list", queryWhere, { login: false });
}

/**
 * 根据发展历程id获取详情
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getvideoReview(queryWhere) {
    return request.get("videoReview/detail", queryWhere, { login: false });
}

/* 理论研究中心 */
export function getTheoryCourses(queryWhere) {
    return request.get("theoryCourse/list", queryWhere, { login: false });
}

/* 理论研究中心三级页 */
export function getTheoryCourse(queryWhere) {
    return request.get("theoryCourse/detail", queryWhere, { login: false });
}

/* 理论研究中心三级页内容详情 */
export function getTheoryCourseDivisionContent(queryWhere) {
    return request.get("theoryCourse/theoryCourseDivisionContent", queryWhere, {
        login: false,
    });
}

/**
 * 获取传统课程体系列表
 * @param {*} queryWhere
 * @returns
 */
export function getTraditionCourseList(queryWhere) {
    return request.get("traditionCourse/list", queryWhere, { login: false });
}

/**
 * 根据传统课程体系id获取详情
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getTradition(queryWhere) {
    return request.get("traditionCourse/detail", queryWhere, { login: false });
}

/**
 * 根据传统课程小节id获取小节详情
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getTraditionCourseDivisionContent(queryWhere) {
    return request.get(
        "traditionCourse/traditionCourseDivisionContent",
        queryWhere,
        {
            login: false,
        }
    );
}

/**
 * 获取资源展示列表(详情)
 * @param {*} queryWhere
 * @returns
 */
export function getResourceDisplay(queryWhere) {
    return request.get("navTab/resourceDisplay", queryWhere, { login: false });
}

/**
 * 获取视频列表（根据视频分类id获取视频列表）
 * @param {*} queryWhere
 * @returns
 */
export function getVideoList(queryWhere) {
    return request.get("course/list", queryWhere, { login: false });
}

/**
 * 根据视频id获取事项详情
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getEncryptionVideo(queryWhere) {
    return request.get("course/detail", queryWhere, { login: false });
}

/**
 * 根据视频vid获取播放权限
 * @param {*} queryWhere  =>{course_vid : ""}
 * @returns
 */
export function getVideoPlayAuth(queryWhere) {
    return request.get("course/getPlayAuth", queryWhere, { login: false });
}

/**
 * 获取视频分类列表（根据视频分类id获取视频分类列表）
 * @param {*} queryWhere => {pid : 0}
 * @returns
 */
export function getVideoCategories(queryWhere) {
    return request.get("courseCategory/list", queryWhere, { login: false });
}

/**
 * 根据视频分类id获取视频分类详情
 * @param {*} queryWhere => {id : 1}
 * @returns
 */
export function getVideoCate(queryWhere) {
    return request.get("courseCategory/detail", queryWhere, { login: false });
}

/*创新课程体系  */
export function getPracticeCoursesByModular(queryWhere) {
    return request.get("practiceModular/practiceCoursesByModular", queryWhere, {
        login: false,
    });
}

/**
 * 获取创新课程体系年级分类列表
 * @param {*} queryWhere
 * @returns
 */
export function getPracticeGradeCategorys(queryWhere) {
    return request.get("practiceCategory/list", queryWhere, { login: false });
}

/* 课题实验专区 */
export function getPracticeCourse(queryWhere) {
    return request.get("practiceCourse/detail", queryWhere, {
        login: false,
    });
}

/**
 * 根据创新课程模块id获取创新课程列表
 * @param {*} id
 * @returns
 */
export function getPracticeCoursesByModularId(id) {
    return request.get("practiceCourse/practiceCoursesByModularId", id, {
        login: false,
    });
}

/**
 * 获取创新课程体系division和tabs
 * @param {*} queryWhere
 * @returns
 */
export function getPracticeTabs(queryWhere) {
    return request.get(
        "practiceCourse/practiceAndTabsByContentId",
        queryWhere,
        {
            login: false,
        }
    );
}

/**
 * 根据创新课程小节id获取创新创新课程详情
 * @param {*} id => {id:1}
 * @returns
 */
export function getDivisionContentByContentId(id) {
    return request.get("practiceCourse/practiceCourseDivisionContent", id, {
        login: false,
    });
}

/*根据创新课程体系年级分类id获取中医启蒙列表  */
export function getPracticeMedicinesByGradeCategoryId(id) {
    return request.get(
        "practiceCategory/practiceMedicinesByGradeCategoryId",
        id,
        {
            login: false,
        }
    );
}

/**
 * 根据创新课程体系年级分类id获取主要阅读部分的文字列表
 * @param {*} queryWhere
 * @returns
 */
export function getPracticeMedicinesMainReadByGradeCategoryId(queryWhere) {
    return request.get(
        "practiceCategory/practiceMedicinesMainReadByGradeCategoryId",
        queryWhere,
        {
            login: false,
        }
    );
}

/**
 * 根据创新课程体系中医启蒙课程id获取创新课程体系中医启蒙课程详情
 * @param {*} queryWhere
 * @returns
 */
export function getPracticeMedicine(queryWhere) {
    return request.get("PracticeMedicine/detail", queryWhere, {
        login: false,
    });
}

/**
 * 教师备课（手册）资源列表（根据教师手册版本id获取教师手册列表）
 * @param {*} queryWhere =>{manual_edition_id：1}
 * @returns
 */
export function getPraparationCourses(queryWhere) {
    return request.get("manual/list", queryWhere, { login: false });
}

/**
 * 根据教师手册id和手册版本id获取教师手册详情
 * @param {*} queryWhere =>{id:19,manual_edition_id：1}
 * @returns
 */
export function getPraparationCourse(queryWhere) {
    return request.get("manual/detail", queryWhere, { login: false });
}
/* 教师备课资源详情 */
export function getpraparationCourse(queryWhere) {
    return request.get("praparationCourseByIdAndEditionId", queryWhere, {
        login: false,
    });
}

/* 教师备课资源详情模块内容 */
export function getPraparationDivisionContentByContenId(queryWhere) {
    return request.get("manual/manualDivisionContent", queryWhere, {
        login: false,
    });
}

/* -------------------------------------------- */

/* 创新课程体系三级页 */
export function getpracticeCourse(queryWhere) {
    return request.get("practiceCourse", queryWhere, { login: false });
}

/* 多媒体中心详情页 */
export function getvideo(queryWhere) {
    return request.get("video", queryWhere, { login: false });
}

export function getUserTrainCourse(queryWhere) {
    return request.get("userTrainCourse", queryWhere, { login: true });
}
/* 课题实验专区 详情 */
export function getvideosBycate(queryWhere) {
    return request.get("videosBycate", queryWhere, { login: false });
}

/* 个人中心课程详情 */
export function getuserTrainCourse(queryWhere) {
    return request.get("userTrainCourse", queryWhere, { login: true });
}

/* 模拟试题 */
export function getExams(queryWhere) {
    return request.get("Exams", queryWhere, { login: true });
}

/* 模拟考试提交 */
export function saveExam(data) {
    return request.post("saveExam", data, { login: true });
}

/* 人才库搜索 */
export function getSearchTrainPersons(queryWhere) {
    return request.get("searchTrainPersons", queryWhere, { login: false });
}
