<template>
  <div class="head">
    <div class="header">
      <div class="show">
        <router-link to="/" class="img"> </router-link>
        <div class="from" v-if="!$store.state.app.token || $store.state.app.userInfo == null">
          <router-link to="/login" class="i">登录</router-link>
          <span class="sp">|</span>
          <a class="i" @click="toRegister()">注册</a>
        </div>
        <div class="from" v-if="$store.state.app.token && $store.state.app.userInfo != null">
          <div class="user-info">
            {{ $store.state.app.userInfo.account }}
            <ul class="ssp-s">
              <li><router-link to="/user">个人中心</router-link></li>
              <li @click="logout()">退出登录</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="chose-register">
      <div class="chose-register-title">
        <h1>请选择您要注册的账号类型</h1>
      </div>
      <div class="chose-register-content clearfix">
        <div class="left">
          <img src="@assets/images/person.png" alt="" />
          <div class="goRegister">
            <a href="/register">去注册个人账号</a>
          </div>
        </div>
        <div class="right">
          <img src="@assets/images/persons.png" alt="" />
          <div class="goRegister">
            <a href="/unitRegister">去注册单位账号</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import $store from "../store";
import { logout } from "@api/user";
export default {
  components: {},
  props: {},
  data() {
    return {
      userInfo: {},
    };
  },
  mounted: function () {
    let that = this;
  },
  created: function () { },
  methods: {
    logout: function () {
      logout().then((res) => {
        if (res.code == 200) {
          this.$store.commit("LOGOUT");
          location.href = location.origin;
        }
      });
    },
    toRegister: function () {
      layui.use(["layer"], function () {
        let layer = layui.layer;
        layer.open({
          title: false,
          type: 1,
          closeBtn: 0,
          anim: 0,
          area: ["40%", "48%"],
          shadeClose: true,
          content: $(".chose-register"),
          skin: "layui-layer-lan",
          end: function () {
            $(".chose-register").hide();
          },
        });
      });
    },
  },
  destroyed: function () {
    $(".layui-layer-shade").remove();
  },
};
</script>
<style scoped>
.img {
  width: 74px;
  height: 140px;
  background: url(../assets/images/shou.png);
  background-size: cover;
  position: absolute;
  left: 76px;
  top: 1px;
}

.header {
  width: 100%;
  height: 60px;
  background: #beb9ae;
}

.show {
  width: 1484px;
  height: 60px;
  margin: 0 auto;
  position: relative;
  display: block !important;
  z-index: 1000;
}

.from {
  position: relative;
  right: 0;
  font-size: 14px;
  float: right;
  height: 100%;
  cursor: pointer;
}

.user-info {
  position: relative;
  height: 100%;
  color: #607a5d;
}

.from:hover .ssp-s {
  display: block;
  cursor: pointer;
}

.ssp-s {
  display: none;
  position: absolute;
  left: -5px;
  top: 25px;
  min-width: 100%;
  line-height: 36px;
  /* padding: 5px 0; */
  padding-top: 11px;
  background-color: transparent;
  z-index: 100;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
}

.ssp-s li {
  position: relative;
  color: #607a5d;
  background-color: #beb9ae;
  line-height: 40px;
  height: 40px;
  text-align: center;
  padding: 0px 10px;
  /* margin-top: 10px; */
  cursor: pointer;
}

.chose-register {
  /* border: 1px solid blue; */
  display: none;
  padding: 10px 20px;
}

.chose-register .chose-register-title {
  position: relative;
  margin: 30px auto;
  font-family: HYQuanTangShiJ;
  text-align: center;
}

.chose-register .chose-register-title h1 {
  position: relative;
  padding: 120px auto;
  display: block;
  font-size: 32px;
  color: #000;
}

.chose-register .chose-register-content {
  position: relative;
  padding: auto 50px;
}

.chose-register .chose-register-content div {
  position: relative;
}

.chose-register .chose-register-content div.left {
  text-align: center;
  float: left;
  width: 45%;
}

.chose-register .chose-register-content div img {
  width: 220px;
  height: auto;
}

.chose-register .chose-register-content div.right {
  float: right;
  width: 45%;
  text-align: center;
}

.chose-register .chose-register-content .goRegister {
  position: relative;
  margin-top: 30px;
  font-size: 22px;
  font-family: HYQuanTangShiJ;
}

.chose-register .chose-register-content .goRegister a {
  display: inline-block;
  background: #c52d3b;
  padding: 20px 25px;
  border-radius: 10px;
  color: #fff;
}
</style>
